import type { Component } from 'solid-js';
import { useLocalization } from '~/hooks/useLocalization';

const LinkExpired: Component = () => {
  const { t } = useLocalization();
  return (
    <div class="flex h-screen w-full items-center justify-center bg-gray-100">
      <div class="items-center rounded-lg bg-white p-8 text-center shadow-lg">
        <h2 class="mb-4 text-2xl font-semibold">{t('Link Expired')}</h2>
        <p class="text-gray-700">{t("The link has expired, but we've sent you another one to your registered email address.")}</p>
      </div>
    </div>
  );
};

export default LinkExpired;
